import { Action } from '@ngrx/store';
import { ActionTypes } from './contact.actions';

export const initialState = {
	step: 1,
	form: null,
	personalInfo: {
		name: '',
		email: '',
		phone: '',
	},
	segmentInfo: {
		segment: '',
	},
	creditInfo: {
		amount: '',
		hasCollateral: '',
		purpose: '',
	},
	contactInfo: {
		medium: '',
		timespan: '',
	},
	message: '',
};

export function contactReducer(state = initialState, action: Action) {
	switch (action.type) {
		case ActionTypes.init:
			state.step = 1;
			break;
		case ActionTypes.showPersonalInfo:
			state.step = 1;
			break;
		case ActionTypes.showSegment:
			state.step = 2;
			break;
		case ActionTypes.showCreditInfo:
			state.step = 3;
			break;
		case ActionTypes.showContactInfo:
			state.step = 4;
			break;
		case ActionTypes.showSegment:
			state.step = 5;
			break;
		case ActionTypes.submit:
			// reset as we are complete
			state = initialState;
			break;
	}

	return state;
}
