import { Component, OnInit } from '@angular/core';
import { ScrollService } from '../navigation/scroll.service';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
	constructor(private srcollService: ScrollService) {}

	ngOnInit() {}
}
