import { Component, OnInit, HostBinding, HostListener } from '@angular/core';
import { WordpressService } from '../wordpress.service';
import { Observable, combineLatest } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ScrollService } from '../navigation/scroll.service';
import { GoogleAnalyticsService } from '../core/google-analytics.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
	@HostBinding('attr.id') id = 'scroll-shell';
	@HostBinding('attr.class') class = 'parallax';

	pages$: Observable<any[]>;
	frontPage$: Observable<any>;
	homepageId: number;

	scrollTop: number;

	constructor(
		private route: ActivatedRoute,
		private googleAnalyticsService: GoogleAnalyticsService,
		private srcollService: ScrollService,
		private wp: WordpressService
	) {}

	@HostListener('scroll', ['$event'])
	onScroll(event) {
		this.scrollTop = event.target.scrollTop;
	}

	ngOnInit() {
		this.loadPages();
	}

	private loadPages() {
		this.frontPage$ = this.wp.getFrontPage();
		this.frontPage$.subscribe((result) => {
			this.pages$ = this.wp.getPages({
				parent: 0,
				orderby: 'menu_order',
				order: 'asc',
				exclude: [3, 97, 102, 156, 158, result.id].join(', '),
			});

			combineLatest(this.pages$, this.route.url, (pages, url) => ({
				pages,
				url,
			})).subscribe((pair) => {
				if (pair.url[0] && pair.url[0].path === 'contact') {
					this.googleAnalyticsService.emitPageView('/contact');
					const element = document.getElementById('contact');
					this.srcollService.scrollToElement(element);
				}
			});
		});
	}
}
