import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-floating-action-button-option',
	templateUrl: './floating-action-button-option.component.html',
	styleUrls: ['./floating-action-button-option.component.scss'],
})
export class FloatingActionButtonOptionComponent implements OnInit {
	isOpen = false;
	doShowLabel = false;

	@Input() parrentOpen: boolean;

	private timeLeft = 2.5;
	interval;

	constructor() {}

	ngOnInit() {}

	open(inverse: boolean) {
		if (inverse) {
			this.isOpen = !this.isOpen;
		} else {
			this.isOpen = true;
		}
	}

	close() {
		this.isOpen = false;
	}

	showLabel() {
		this.doShowLabel = true;

		this.interval = setInterval(() => {
			if (this.timeLeft > 0) {
				this.timeLeft--;
			} else {
				clearInterval(this.interval);
				this.timeLeft = 1;
				this.doShowLabel = false;
			}
		}, 1000);
	}
}
