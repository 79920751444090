import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { WpPageComponent } from './wp-page/wp-page.component';
import { WpImageComponent } from './wp-image/wp-image.component';
import { WpHomePageComponent } from './wp-home-page/wp-home-page.component';

import { SafeHtmlPipe } from './safe-html.pipe';
import { RouteTransformerDirective } from './route-transformer.directive';

import { HomeComponent } from './home/home.component';
import { ButtonLinkComponent } from './button-link/button-link.component';

import { HeaderComponent } from './header/header.component';
import { SegmentComponent } from './segment/segment.component';
import { FooterComponent } from './footer/footer.component';
import { ContactComponent } from './contact/contact.component';
import { MenuComponent } from './menu/menu.component';

import { FloatingActionButtonComponent } from './floating-action-button/floating-action-button.component';
import { FloatingActionButtonOptionComponent } from './floating-action-button-option/floating-action-button-option.component';
import { FloatingActionButtonMessengerComponent } from './floating-action-button-messenger/floating-action-button-messenger.component';
import { FloatingActionButtonPhoneComponent } from './floating-action-button-phone/floating-action-button-phone.component';
import { StoreModule } from '@ngrx/store';
import { contactReducer } from './contact/contact.reducer';
import { CoreModule } from './core/core.module';
import { SafeStylePipe } from './safe-style.pipe';

// import { FacebookModule } from 'ngx-facebook';

@NgModule({
	declarations: [
		AppComponent,
		WpImageComponent,
		WpPageComponent,
		WpHomePageComponent,
		HomeComponent,
		ButtonLinkComponent,
		SafeHtmlPipe,
		SafeStylePipe,
		RouteTransformerDirective,
		SegmentComponent,
		FooterComponent,
		ContactComponent,
		MenuComponent,
		FloatingActionButtonComponent,
		FloatingActionButtonOptionComponent,
		FloatingActionButtonMessengerComponent,
		FloatingActionButtonPhoneComponent,
		HeaderComponent,
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		AppRoutingModule,
		// FacebookModule.forRoot(),
		ReactiveFormsModule,
		StoreModule.forRoot({ contact: contactReducer }),
		CoreModule,
	],
	providers: [],
	bootstrap: [AppComponent],
})
export class AppModule {}
