import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { MenuItem } from './shared/models/menuItem';
import { MenuItemMapper } from './shared/mappers/menu-item.mapper';

@Injectable({
	providedIn: 'root',
})
export class WordpressService {
	constructor(private http: HttpClient) {}

	getPosts(): Observable<any[]> {
		return this.http
			.get<any[]>(`${environment.wpUrl}/${environment.wpApi}/posts`, {
				params: {
					per_page: '6',
				},
			})
			.pipe(catchError(this.handleError<any>('getPosts')));
	}

	getFrontPage(): Observable<any[]> {
		return this.http
			.get<any[]>(`${environment.wpUrl}/${environment.wpApi}/frontpage`, {})
			.pipe(catchError(this.handleError<any>('getFrontPage')));
	}

	getPages(paramsObject): Observable<any[]> {
		return this.http
			.get<any[]>(`${environment.wpUrl}/${environment.wpApi}/pages`, {
				params: paramsObject,
			})
			.pipe(catchError(this.handleError<any>('getPages')));
	}

	getMenus(slug: string): Observable<MenuItem[]> {
		return this.http
			.get<any>(
				`${environment.wpUrl}/${environment.wpMenuApi}/menus/${slug}`,
				{}
			)
			.pipe(
				map((response: any) => {
					return response.items.map((item: any) =>
						MenuItemMapper.mapToModel(item)
					);
				}),
				catchError(this.handleError<any>('getMenus'))
			);
	}

	getMedia(paramsObject): Observable<any> {
		return this.http
			.get<any>(`${environment.wpUrl}/${environment.wpApi}/media`, {
				params: paramsObject,
			})
			.pipe(catchError(this.handleError<any>('getMedia')));
	}

	getMediaById(id, paramsObject): Observable<any> {
		return this.http
			.get<any>(`${environment.wpUrl}/${environment.wpApi}/media/${id}`, {
				params: paramsObject,
			})
			.pipe(catchError(this.handleError<any>('getMediaById')));
	}

	sendContact(paramsObject): Observable<any> {
		return this.http
			.post<any>(
				`${environment.wpUrl}/${environment.wpContactApi}/send/?sendConfirmation=true`,
				paramsObject
			)
			.pipe(catchError(this.handleError<any>('sendContact')));
	}

	/**
	 * Handle Http operation that failed.
	 * Let the app continue.
	 * @param operation - name of the operation that failed
	 * @param result - optional value to return as the observable result
	 */
	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// TODO: better job of transforming error for user consumption
			// this.log(`${operation} failed: ${error.message}`);

			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
	}
}
