import { MenuItem } from '../models/menuItem';
import { environment } from 'src/environments/environment';

export class MenuItemMapper {
	// todo : create a dto for the menu items
	static mapToModel(item: any): MenuItem {
		const menuItem: MenuItem = new MenuItem();
		menuItem.id = item.ID;
		menuItem.parentId = item.menu_item_parent;
		menuItem.sort = item.menu_order;
		menuItem.title = item.title;

		menuItem.segments = item.url
			.replace(environment.siteUrl, '')
			.split('/')
			.filter((segment: string) => segment !== '');

		if (!!item.child_items) {
			item.child_items.forEach(child => {
				menuItem.addChild(MenuItemMapper.mapToModel(child));
			});
		}

		return menuItem;
	}
}
