import { MediaSize } from './mediaSize';

export class Media {
	alt: string;

	sizes: MediaSize[] = [];

	get imageMap(): string[] {
		return this.sizes.map((item) => `${item.src} ${item.width}w`);
	}

	get defaultImage(): string {
		return this.sizes[this.sizes.length - 1].src;
	}

	constructor(alt) {
		this.alt = alt;
	}

	addSize(name, src, width, heigth): void {
		const image = new MediaSize();

		image.name = name;
		image.width = width;
		image.height = heigth;
		image.src = src;

		this.sizes.push(image);
	}
}
