import { Component } from '@angular/core';
import { GoogleAnalyticsService } from '../core/google-analytics.service';
import { ScrollService } from '../navigation/scroll.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
	constructor(
		private srcollService: ScrollService,
		private googleAnalyticsService: GoogleAnalyticsService
	) {}

	gotoContactPhone() {
		this.googleAnalyticsService.emitPageView('/contact-phone');
	}

	gotoContact(event) {
		this.googleAnalyticsService.emitPageView('/contact');

		const element = document.getElementById('contact');
		this.srcollService.scrollToElement(element);

		event.stopPropagation();
		event.preventDefault();
	}
}
