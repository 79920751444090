import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SegmentComponent } from './segment/segment.component';

export const routes: Routes = [
	{ path: 'home', pathMatch: 'full', redirectTo: '' },
	{ path: '', component: HomeComponent, pathMatch: 'full' },
	{ path: 'contact', component: HomeComponent, pathMatch: 'full' },
	{ path: ':segment', component: SegmentComponent },
	{ path: ':segment/:fragment', component: SegmentComponent },
	{ path: '**', component: HomeComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
