import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-wp-home-page',
	templateUrl: './wp-home-page.component.html',
	styleUrls: ['./wp-home-page.component.scss'],
})
export class WpHomePageComponent {
	@Input() page: any;
	@Input() id: string;
	@Input() scrollTop: number;
}
