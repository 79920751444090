import { Component, Input, ElementRef } from '@angular/core';
import {
	FormBuilder,
	Validators,
	FormGroup,
	FormControl,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { WordpressService } from '../wordpress.service';
import {
	trigger,
	transition,
	animate,
	style,
	state,
} from '@angular/animations';
import { timer } from 'rxjs';
import { ScrollService } from '../navigation/scroll.service';

@Component({
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.scss'],
	animations: [
		trigger('messageAnimation', [
			transition(':enter', [
				style({
					opacity: '0',
					maxHeight: '0px',
				}),
				animate(300),
			]),
			transition(':leave', [
				animate(
					300,
					style({
						opacity: '0',
						maxHeight: '0px',
					})
				),
			]),
			state(
				'*',
				style({
					opacity: '1',
					maxHeight: '100px',
				})
			),
		]),
	],
})
export class ContactComponent {
	@Input() scrollTop: number;

	showSuccessMessage = false;
	segment = '';

	contactForm = this.fb.group({
		personalInfo: this.fb.group({
			name: ['', Validators.required],
			email: ['', [Validators.required, Validators.email]],
			phone: ['', Validators.required],
		}),
		segmentInfo: this.fb.group({
			segment: [''],
		}),
		creditInfo: this.fb.group({
			amount: [''],
			hasCollateral: [''],
			purpose: [''],
			purposeText: [''],
		}),
		contactInfo: this.fb.group({
			medium: [''],
			timespanMorning: [''],
			timespanAfternoon: [''],
			timespanEvening: [''],
		}),
		message: [''],
	});

	step = 1;

	get personalInfo() {
		return this.contactForm.controls['personalInfo'] as FormGroup;
	}
	get segmentInfo() {
		return this.contactForm.controls['segmentInfo'] as FormGroup;
	}
	get creditInfo() {
		return this.contactForm.controls['creditInfo'] as FormGroup;
	}
	get contactInfo() {
		return this.contactForm.controls['contactInfo'] as FormGroup;
	}
	get message() {
		return this.contactForm.controls['message'] as FormGroup;
	}

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		private wp: WordpressService,
		private srcollService: ScrollService,
		private element: ElementRef
	) {}

	onSubmit() {
		const data = [
			{
				name: 'subject',
				value: 'Uw bericht op boelaertkredieten.be',
				type: 'subject',
			},
			{
				name: 'Naam',
				value: this.personalInfo.controls.name.value,
				type: 'text',
			},
			{
				name: 'E-mail',
				value: this.personalInfo.controls.email.value,
				type: 'email',
			},
			{
				name: 'Tel.',
				value: this.personalInfo.controls.phone.value,
				type: 'phone',
			},
			{
				name: 'Segment',
				value: this.segmentInfo.controls.segment.value,
				type: 'text',
			},
			{
				name: 'Kredietbedrag',
				value: this.creditInfo.controls.amount.value,
				type: 'text',
			},
			{
				name: 'Pand in bezit',
				value: this.creditInfo.controls.hasCollateral.value,
				type: 'text',
			},
			{
				name: 'Doel van krediet',
				value: this.creditInfo.controls.purpose.value,
				type: 'text',
			},
			{
				name: 'Doel van krediet - info',
				value: this.creditInfo.controls.purposeText.value,
				type: 'textarea',
			},
			{
				name: 'Contacteer per',
				value: this.contactInfo.controls.medium.value,
				type: 'text',
			},
		];

		if (this.contactInfo.controls.medium.value === 'telefoon') {
			const values: string[] = [];
			if (this.contactInfo.controls.timespanMorning.value) {
				values.push('Voormiddag');
			}
			if (this.contactInfo.controls.timespanAfternoon.value) {
				values.push('Namiddag');
			}
			if (this.contactInfo.controls.timespanEvening.value) {
				values.push('na 17u');
			}
			data.push({
				name: 'Contacteer tijdstip',
				value: values.join('/'),
				type: 'text',
			});
		}

		data.push({
			name: 'Extra info / vragen',
			value: this.message.value,
			type: 'textarea',
		});

		this.wp.sendContact(data).subscribe((result) => {
			this.segment = this.segmentInfo.controls['segment'].value;
			this.showSuccessMessage = true;
			this.contactForm.reset();
			this.step = 1;

			timer(5000).subscribe(() => {
				this.showSuccessMessage = false;
			});
		});
	}

	gotoPreviousStep() {
		if (this.step === 3) {
			if (
				this.router.url.indexOf('particulieren') > 0 ||
				this.router.url.indexOf('zelfstandigen') > 0
			) {
				this.step--;
			}
		}
		this.step--;

		// const element = document.getElementById('contact');
		this.srcollService.scrollToElement(this.element.nativeElement);
	}

	gotoNextStep() {
		if (this.step === 1) {
			if (this.router.url.indexOf('particulieren') > 0) {
				this.segmentInfo.controls['segment'].setValue('particulier');
				this.step++;
			} else if (this.router.url.indexOf('zelfstandigen') > 0) {
				this.segmentInfo.controls['segment'].setValue('zelfstandige-kmo');
				this.step++;
			} else {
				this.segmentInfo.controls['segment'].setValue('');
			}
		}
		this.step++;

		const element = document.getElementById('contact');
		this.srcollService.scrollToElement(element);
	}

	validateAllFields(formgroup: FormGroup) {
		Object.keys(formgroup.controls).forEach((field) => {
			const control = formgroup.get(field);
			if (control instanceof FormControl) {
				control.updateValueAndValidity({ onlySelf: true });
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {
				this.validateAllFields(control);
			}
		});
	}
}
