import { Component, OnInit, HostBinding, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { pairwise, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import { WordpressService } from '../wordpress.service';
import { ScrollService } from '../navigation/scroll.service';
import { Message } from '../pub-sub/Message';

@Component({
	selector: 'app-segment',
	templateUrl: './segment.component.html',
	styleUrls: ['./segment.component.scss'],
})
export class SegmentComponent implements OnInit {
	@HostBinding('attr.id') id = 'scroll-shell';
	@HostBinding('attr.class') class = 'parallax';

	pages$: Observable<any[]>;

	sectionPage$: Observable<any>;

	scrollTop: number;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private wp: WordpressService,
		private srcollService: ScrollService
	) {}

	@HostListener('scroll', ['$event'])
	onScroll(event) {
		this.scrollTop = event.target.scrollTop;
	}

	ngOnInit() {
		// this.routingService.activate();
		// this.pubSubService.Stream.subscribe(message => this.processMessage(message));

		this.route.params.pipe(pairwise()).subscribe((params) => {
			const previousParams = params[0];
			const currentParams = params[1];

			// reuse route, state refreshes, page scrolls to top
			this.router.routeReuseStrategy.shouldReuseRoute = function () {
				return true;
			};

			if (previousParams.segment !== currentParams.segment) {
				this.loadPages();
			} else if (previousParams.fragment !== currentParams.fragment) {
				if (currentParams.fragment == null || currentParams.fragment === '') {
					this.srcollService.scrollToTop();
				} else {
					const element = document.getElementById(currentParams.fragment);
					this.srcollService.scrollToElement(element);
				}
			}
		});

		this.loadPages();
	}

	private loadPages() {
		if (this.route.snapshot.params.segment) {
			this.sectionPage$ = this.wp
				.getPages({ slug: this.route.snapshot.params.segment })
				.pipe(map((pages) => pages[0]));
			this.sectionPage$.subscribe((result) => {
				this.pages$ = this.wp.getPages({
					parent: result.id,
					orderby: 'menu_order',
					order: 'asc',
				});
				this.srcollService.scrollToTop();
			});
		}
	}

	private processMessage(message: Message) {
		switch (message.name) {
			case 'loadPages':
				this.loadPages();
				break;
		}
	}
}
