import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-button-link',
	templateUrl: './button-link.component.html',
	styleUrls: ['./button-link.component.scss'],
})
export class ButtonLinkComponent implements OnInit {
	@Input() text: string;
	@Input() path: string;
	@Input() styles: string;

	constructor() {}

	ngOnInit() {}
}
