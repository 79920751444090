import { Injectable, Inject } from '@angular/core';
import { DOCUMENT, PlatformLocation } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class ScrollService {
	private readonly scrollBehavior: ScrollBehavior = 'smooth';
	private readonly blockAlignement: ScrollLogicalPosition = 'start';

	constructor(
		@Inject(DOCUMENT) private document: any,
		private location: PlatformLocation
	) {
		// On resize, the toolbar might change height, so "invalidate" the top offset.
		// fromEvent(window, 'resize').subscribe(() => this._topOffset = null);
	}

	scroll() {
		const hash = this.getCurrentHash();
		const element: HTMLElement = hash
			? this.document.getElementById(hash)
			: null;

		this.scrollToElement(element);
	}

	scrollToElement(element: Element | null) {
		if (element) {
			element.scrollIntoView({
				block: this.blockAlignement,
				behavior: this.scrollBehavior,
			});
			// if (window && window.scrollBy) {
			//   const scollContainer = document.getElementById('scroll-shell');
			//   // const containerOffset = scollContainer.getBoundingClientRect().top;
			//   this.scrollTo((element.getBoundingClientRect().top + window.pageYOffset));
			//   /*if (window.pageYOffset < 20) {
			//     this.scrollTo(-window.pageYOffset);
			//   }*/
			// }
		}
	}

	scrollTo(y: number) {
		const scollContainer = document.getElementById('scroll-shell');

		try {
			scollContainer.scrollTo({
				left: 0,
				top: y,
				behavior: this.scrollBehavior,
			});
		} catch (e) {
			window.scrollTo(0, 0);
		}
	}

	scrollToTop() {
		this.scrollTo(0);
	}

	inViewport(element: Element | null) {
		if (element) {
			const bounding = element.getBoundingClientRect();

			return (
				bounding.top <=
					(window.innerHeight || document.documentElement.clientHeight) &&
				bounding.left <=
					(window.innerWidth || document.documentElement.clientWidth) &&
				bounding.top >= 0 // &&
				// bounding.right >= 0
			);
		}
	}

	private getCurrentHash() {
		return decodeURIComponent(this.location.hash.replace(/^#/, ''));
	}
}
