import {
	Component,
	OnInit,
	Input,
	OnChanges,
	SimpleChanges,
	ElementRef,
} from '@angular/core';
import { WordpressService } from '../wordpress.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Media } from '../shared/models/media';

@Component({
	selector: 'app-wp-image',
	templateUrl: './wp-image.component.html',
	styleUrls: ['./wp-image.component.scss'],
})
export class WpImageComponent implements OnInit, OnChanges {
	@Input() mediaId: number;
	@Input() depth: string;
	@Input() scrollTop: number;

	image$: Observable<Media>;
	image: Media;
	imageStyle: string;

	private mediaSizes: string[] = [
		'thumbnail',
		'medium',
		'medium_large',
		'large',
		'full',
	];

	constructor(private wp: WordpressService, private elRef: ElementRef) {
		this.depth = 'back';
	}

	ngOnInit() {
		this.image$ = this.wp
			.getMediaById(this.mediaId, { context: 'embed', media_type: 'image' })
			.pipe(
				map((mediaItem) => {
					const media = new Media(mediaItem.alt_text);

					this.mediaSizes.forEach((element) => {
						media.addSize(
							element,
							mediaItem.media_details.sizes[element].source_url,
							mediaItem.media_details.sizes[element].width,
							mediaItem.media_details.sizes[element].height
						);
					});

					return media;
				})
			);
	}

	ngOnChanges(changes: SimpleChanges): void {
		const bodyRect = document.body.getBoundingClientRect();
		const elemRect = this.elRef.nativeElement.getBoundingClientRect();
		const elementOffset = elemRect.top - bodyRect.top;
		const windowHeight = window.innerHeight - 108;

		const offset = elementOffset - windowHeight;
		const multiplyer = this.depth === 'deep' ? 9 : 12;

		let tranformY = 0;
		if (offset < 0) {
			tranformY = (Math.abs(offset) / windowHeight) * multiplyer;
		}
		this.imageStyle = `transform: translate(-50%, -${tranformY}%)`;
	}
}
