export class MenuItem {
	// todo : encapsulate
	id: number;
	parentId: number;
	sort: number;
	title: string;
	segments: string[];

	children: MenuItem[];

	get slug() {
		let slug = '';
		if (this.segments.length > 0) {
			slug = this.segments[this.segments.length - 1];
		}

		return slug;
	}

	get routerlink() {
		let url = '/';
		if (this.segments.length === 0) {
			url = '';
		} else {
			url += this.segments.join('/');
		}
		return url;
	}

	constructor() {
		this.segments = [];
		this.children = [];
	}

	public addChild(child: MenuItem): void {
		this.children.push(child);
	}
}
