import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { MenuItem } from '../shared/models/menuItem';
import { WordpressService } from '../wordpress.service';
import { ScrollService } from '../navigation/scroll.service';
import { GoogleAnalyticsService } from '../core/google-analytics.service';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
	menuItems$: Observable<MenuItem[]>;

	menuItems: MenuItem[];

	isOpen = false;

	constructor(
		private wp: WordpressService,
		private srcollService: ScrollService,
		private googleAnalyticsService: GoogleAnalyticsService
	) {}

	ngOnInit() {
		this.menuItems$ = this.wp.getMenus('top');
	}

	openSideBar(inverse?: boolean) {
		if (inverse) {
			this.isOpen = !this.isOpen;
		} else {
			this.isOpen = true;
		}
	}

	closeSideBar(inverse?: boolean) {
		if (inverse) {
			this.isOpen = !this.isOpen;
		} else {
			this.isOpen = false;
		}
	}

	gotoContact(event) {
		this.googleAnalyticsService.emitPageView('/contact');

		const element = document.getElementById('contact');
		this.srcollService.scrollToElement(element);

		this.closeSideBar();

		event.stopPropagation();
		event.preventDefault();
	}
}
